import React from "react";
import { InlineWidget } from "react-calendly";

const EnterpriseCalendly = () => {
  return (
    <div className="inline-widget">
      <InlineWidget url="https://calendly.com/tongston" />
    </div>
  );
};

export default EnterpriseCalendly;
