import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CardBox2 from "./Cards/CardBox2";
import Coworking from "../../../Assets/img/coworking.jpg";
import Coworking1 from "../../../Assets/img/workspace/Coworking1.jpg";
import Coworking2 from "../../../Assets/img/workspace/Coworking2.jpg";
import Coworking3 from "../../../Assets/img/workspace/Coworking3.jpg";
import Divider from "@mui/material/Divider";
import backpat from "../../../Assets/img/Picture3.png";
import Button from "@mui/material/Button";
import { Train } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";

const BoxFeatures = styled(Box)`
  display: flex;
  align-items: center;
  max-width: 90%;
  gap: 10%;
  margin-left: 1em;

  & .img {
    border-radius: 60% 40% 19% 81% / 45% 43% 57% 55%;
    max-width: 40%;
    margin-bottom: 40%;
    // margin-right: 4em;
  }

  @media (max-width: 1000px) {
    flex-direction: column-reverse;

    & .img {
      margin-bottom: 10%;
      max-width: 60%;
    }
  }
`;

const ContainerBox = styled(Box)`
  background: url(${backpat}) center no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 2em;
  width: 100%;
  padding: 0 5%;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;
// SC

const CoworkingSpace = () => {
  const images = [
    {
      id: 1,
      url: Coworking,
    },
    {
      id: 2,
      url: Coworking1,
    },
    {
      id: 3,
      url: Coworking2,
    },
    {
      id: 3,
      url: Coworking3,
    },
  ];

  return (
    <ContainerBox className="Hub1">
      <Box
        sx={{
          border: "solid 2px #b3b3b3",
          borderRadius: "5px",
          p: 3,
          width: { sm: 400, xs: 300 },
          mb: "10%",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#0C0145",
            fontSize: { xs: "11px", sm: "17px" },
            fontWeight: 600,
            mb: 1,
          }}
        >
          Your Own WorkSpace
        </Typography>

        <Typography component="p" sx={{ fontSize: "15px", pt: "3" }}>
          Our entrepreneurial coworking space is designed with you being an
          entrepreneurial thinker in mind. It’s a great place to access media,
          enterprise, education services; and network with others to make your
          business - valuable, influential & profitable. Drop in and hot desk in
          our entrepreneurial workspace today!
        </Typography>
      </Box>
      <Divider
        sx={{ ml: 4, display: { xs: "none", sm: "block" } }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
      <BoxFeatures>
        <div data-aos="fade-up" data-aos-duration="900">
          <CardBox2 />
        </div>
        {/* <img src={Coworking} alt="" className="img" /> */}

        <Carousel
          className="crsl"
          autoFocus={true}
          autoPlay={true}
          infiniteLoop
          centerMode
        >
          {images.map((image) => (
            <img
              key={image.id}
              src={image.url}
              alt=""
              // className="img"
            />
          ))}
        </Carousel>
      </BoxFeatures>
    </ContainerBox>
  );
};

export default CoworkingSpace;
